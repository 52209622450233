<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="card-title w-100 d-flex justify-content-between">
        <h1>Dersler </h1>
        <a href="javascript:void(0)"
           data-bs-toggle="modal"
           @click="lesson = {}"
           data-bs-target="#add_modal" class="btn btn-primary">
          <span class="svg-icon svg-icon-1"><i class="fa fa-plus"></i> </span>Yeni Ders Ekle</a>
      </div>
    </div>
    <div class="card-body pt-0 pb-5">
      <veri-tablo :datas="datas" :headers="tableHeader" :links="links" :metas="metas">
        <template v-slot:cell-title="{ row: item }">
          <div class="d-flex align-items-center ps-5">
            <div class="symbol symbol-45px me-5 p-3" :style="{'background-color' : item.color}">
              <img :src="item.icon" alt="">
            </div>
            <div class="d-flex justify-content-start flex-column">
              <a href="#" class="text-dark fw-bolder text-hover-primary fs-6 text-start">{{ item.title }}</a>
              <span class="text-muted fw-bold text-start d-block fs-7"> {{ item.explanation.slice(0, 40) }}...</span>
            </div>
          </div>
        </template>
        <template v-slot:cell-tag="{ row: item }">
          <el-tag v-for="(tag, i) in item.tags.splice(0,5)" :key="i" class="ms-2">{{ tag }}</el-tag>
        </template>
        <template v-slot:cell-actions="{row:item}">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link rounded p-3 bg-light">İşlemler<el-icon class="el-icon--right"><arrow-down/></el-icon></span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="lesson = item">
                  <a href="javascript:void(0)" class="text-hover-primary" data-bs-toggle="modal"
                     data-bs-target="#update_modal"> Güncelle </a>
                </el-dropdown-item>
                <el-dropdown-item @click="deleteItem(item)">Sil</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </veri-tablo>
    </div>
  </div>
  <empty-modal modalId="add_modal" ref="add_modal__ref" modalTitle="Ders Ekle">
    <template v-slot:ModalBody>
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-md-3">
              <el-upload
                  class="avatar-uploader mb-8"
                  :style="{'background-color': lesson.color}"
                  :on-success="handleImageSuccess"
                  name="image"
                  :action="apiBaseurl + '/imageUpload?type=package'"
                  :show-file-list="false">
                <img v-if="lesson.icon" :src="lesson.icon" class="avatar"/>
                <el-icon v-else
                         class="avatar-uploader-icon d-flex justify-content-center align-items-center align-content-center">
                  <Plus/>
                </el-icon>
              </el-upload>
              <div class="d-flex flex-column fv-row">
                <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                  <span class="required">Renk</span>
                  <el-popover v-if="false" placement="top-start" title="Title" :width="200" trigger="hover"
                              content="Ders Görselinin arka plan rengi">
                    <template #reference>
                      <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                    </template>
                  </el-popover>
                </label>
                <input type="color" v-model="lesson.color" class="form-control form-control-color" style="width:10rem;">
              </div>
            </div>
            <div class="col-md-9">
              <div class="d-flex flex-column mb-8">
                <label class="required fs-6 fw-bold mb-2">Başlık</label>
                <input v-model="lesson.title" type="text" class="form-control form-control-solid" placeholder="Başlık"
                       aria-label="Başlık"/>
              </div>
              <div class="d-flex flex-column mb-8">
                <label class="required fs-6 fw-bold mb-2">Açıklama
                  <el-popover placement="top-start" title="Title" :width="200" trigger="hover"
                              content="this is content, this is content, this is content">
                    <template #reference>
                      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                         data-bs-original-title="Specify a target priorty" aria-label="Specify a target priorty"></i>
                    </template>
                  </el-popover>
                </label>
                <textarea v-model="lesson.explanation" class="form-control form-control-solid" rows="6" cols="6"
                          placeholder="Açıklama" aria-label="Açıklama"></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <div class="d-flex flex-column mb-8 fv-row">
                <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                  <span class="required">Etiket</span>
                  <el-popover placement="top-start" title="Title" :width="200" trigger="hover"
                              content="this is content, this is content, this is content">
                    <template #reference>
                      <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                    </template>
                  </el-popover>
                </label>
                <el-select
                    v-model="tags"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    :reserve-keyword="false"
                    placeholder="Etiket seçin veya yeni oluşturun"
                >
                  <el-option
                      v-for="item in options"
                      :key="item.tagId"
                      :label="item.title"
                      :value="item.tagId"
                  />
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:ModalFooter>
      <a @click="save()" class="btn btn-primary">Kaydet</a>
    </template>
  </empty-modal>
  <empty-modal modalId="update_modal" ref="update_modal__ref" modalTitle="Ders Güncelle">
    <template v-slot:ModalBody>
    {{ lesson }}
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-md-3">
              <el-upload
                  class="avatar-uploader mb-8"
                  :style="{'background-color': lesson.color}"
                  name="image"
                  :on-success="handleImageSuccess"
                  :action="apiBaseurl + '/imageUpload?type=package'"
                  :show-file-list="false">
                <img v-if="lesson.icon" :src="lesson.icon" class="avatar"/>
                <el-icon v-else
                         class="avatar-uploader-icon d-flex justify-content-center align-items-center align-content-center">
                  <Plus/>
                </el-icon>
              </el-upload>
              <div class="d-flex flex-column fv-row">
                <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                  <span class="required">Renk</span>
                  <el-popover placement="top-start" title="Title" :width="200" trigger="hover"
                              content="this is content, this is content, this is content">
                    <template #reference>
                      <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                    </template>
                  </el-popover>
                </label>
                <input type="color" v-model="lesson.color" class="form-control form-control-color" style="width:10rem;">
              </div>
            </div>
            <div class="col-md-9">
              <div class="d-flex flex-column mb-8">
                <label class="required fs-6 fw-bold mb-2">Başlık</label>
                <input v-model="lesson.title" type="text" class="form-control form-control-solid" placeholder="Başlık"
                       aria-label="Başlık"/>
              </div>
              <div class="d-flex flex-column mb-8">
                <label class="fs-6 fw-bold mb-2">Açıklama
                  <el-popover placement="top-start" title="Title" :width="200" trigger="hover"
                              content="Açıklama Alanı kullanıcıların site içi aramalarını kolaylaştırmak için kullanılmaktadır">
                    <template #reference>
                      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                         data-bs-original-title="Specify a target priorty" aria-label="Specify a target priorty"></i>
                    </template>
                  </el-popover>
                </label>
                <textarea v-model="lesson.explanation" class="form-control form-control-solid" rows="6" cols="6"
                          placeholder="Açıklama" aria-label="Açıklama"></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <div class="d-flex flex-column mb-8 fv-row">
                <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                  <span >Etiket</span>
                  <el-popover placement="top-start" title="Title" :width="200" trigger="hover"
                              content="Etiketler kullanıcıların dersleri bulmasını kolaylaştırmak için kullanılmaktadır">
                    <template #reference>
                      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                         data-bs-original-title="Specify a target priorty" aria-label="Specify a target priorty"></i>
                    </template>
                  </el-popover>
                </label>
                <el-select
                    v-model="lesson.tags"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    :reserve-keyword="false"
                    placeholder="Etiket seçin veya yeni oluşturun"
                >
                  <el-option
                      v-for="item in options"
                      :key="item.tagId"
                      :label="item.title"
                      :value="item.tagId"
                  />
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>

    </template>
    <template v-slot:ModalFooter>
      <a @click="update()" class="btn btn-primary"> Kaydet </a>
      <button @click="update_modal__ref.close()" class="btn btn-danger"> Kapat</button>
    </template>
  </empty-modal>
</template>
<script>

import {useStore} from "vuex";
import {ArrowDown, Plus} from '@element-plus/icons-vue';
import {computed, onMounted, reactive, ref} from "vue";
import VeriTablo from '@/components/tablo/VeriTablo.vue'
import EmptyModal from '@/components/modals/forms/EmptyModal.vue';
import {useRouter} from "vue-router";
import {inject} from 'vue';
import useCruds from '@/composables/useCrud.js';
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";


export default {
  components: {
    VeriTablo,
    EmptyModal,
    ArrowDown,
    Plus
  },
  setup() {


    const tags = ref();
    const options = ref();

    const apiBaseurl = ref(process.env.VUE_APP_API_URL);
    const lesson = ref({title: '', explanation: '', color: '',imageUrl:'',});
    const payload = ref({slug: "/lessons", data: lesson.value})

    const add_modal__ref = ref();
    const update_modal__ref = ref();

    const imageUrl = ref('');
    const {saveCrud, updateCrud, deleteCrud, loadCrud} = useCruds();

    const tableHeader = [
      {
        name: "Ders Adı",
        key: 'title'
      },
      {
        name: "Etiketler",
        key: "tag",
      },
      {
        name: "İşlemler",
        key: "actions",
      },
    ];


    const store = useStore();
    const router = useRouter();

    const datas = ref({});
    const metas = ref({});
    const links = ref({});

    const save = () => {
      saveCrud({slug:'/lessons',data:lesson.value}).then(
          function (value) {
            datas.value.unshift(value.data);
            add_modal__ref.value.close();
          }
      );
    };
    const update = () => {
      let l = {
        title:lesson.value.title,
        explanation:lesson.value.explanation,
        color:lesson.value.color,
      }
      updateCrud({slug:"/lessons/" + lesson.value.lessonId, data:l}).then(
          function (value) {
            update_modal__ref.value.close();
          }
      );
    };
    const emitter = inject('emitter');

    const deleteItem = (item) => {
      payload.value.slug = "/lessons/" + item.lessonId;
      deleteCrud({slug:"/lessons/" + item.lessonId}).then(function (value) {
        if (value) {
          for (let i = 0; i < datas.value.length; i++) {
            if (datas.value[i] === item) {
              datas.value.splice(i, 1);
            }
          }
        }
      });

    };

   
    const handleImageSuccess = ( response, uploadFile ) => {
        console.log(response);
        //TODO
        lesson.value.icon = response.data.image;
      }



    onMounted(() => {

      setCurrentPageBreadcrumbs("Genel Bakış", ["Dersler"]);

      loadCrud('lessons').then(function (response) {
        if (response.success) {
          datas.value = response.data;
          links.value = response.links;
          metas.value = response.meta;
        }
      });
      loadCrud('tags').then(function (response) {
        options.value = response.data;
      });
      emitter.on('PageButtonClicked', (value) => {
        if (router.currentRoute.value.path == "/lessons") {
          loadCrud('/lessons', value).then(function (response) {
            if (response.success) {
              datas.value = response.data;
              links.value = response.links;
              metas.value = response.meta;
            }
          });
        }
      });
    });

    return {
      tableHeader,
      datas,
      lesson,
      deleteItem,
      save,
      update,
      links,
      metas,
      add_modal__ref,
      update_modal__ref,
      imageUrl,
      tags,
      options,
      handleImageSuccess,
      apiBaseurl,
    }
  }
}
</script>
<style scoped>
  .avatar-uploader {
    width: 100%;
    height: 180px;
    border-radius: 6px;
    border: 1px dashed #d9d9d9;
    display:flex;
    align-items:center;
    justify-content:center;
    align-content:center;
  }
  .avatar-uploader img{
    width:100%;
    height:100%;
  }
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    transition: var(--el-transition-duration-fast);
  }
  .avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
  }
  .el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
  }
</style>